<template>
  <svg viewBox="0 0 24 18">
    <path
      d="M9.90918 0.835772C10.707 -0.278589 12.3638 -0.278591 13.1616 0.83577L22.6935 14.15C23.641 15.4735 22.695 17.3143 21.0673 17.3143H2.00353C0.375799 17.3143 -0.570208 15.4735 0.377318 14.15L9.90918 0.835772Z"
    />
  </svg>
</template>

<script>
export default {
  name: "UserDDArrowIcon",
};
</script>
